.widget-preloader-frame {
  z-index: 1000;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity 2s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.widget-preloader-frame.hidden, .widget-preloader-frame.hidden .widget-preloader {
  pointer-events: none;
  opacity: 0;
}

.widget-preloader-frame .widget-preloader {
  width: 5em;
  height: 5em;
  font-size: 24px;
  position: relative;
}

@media (width <= 1024px) {
  .widget-preloader-frame .widget-preloader {
    font-size: 18px;
  }
}

@media (width <= 320px) {
  .widget-preloader-frame .widget-preloader {
    font-size: 12px;
  }
}

.widget-preloader-frame .widget-preloader div {
  background-color: #ffd180;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 2em;
}

.widget-preloader-frame .widget-preloader div:first-child {
  animation: .6s infinite widget-preloader-1;
  left: 0;
}

.widget-preloader-frame .widget-preloader div:nth-child(2) {
  animation: .6s infinite widget-preloader-2;
  left: 0;
}

.widget-preloader-frame .widget-preloader div:nth-child(3) {
  animation: .6s infinite widget-preloader-2;
  left: 2em;
}

.widget-preloader-frame .widget-preloader div:nth-child(4) {
  animation: .6s infinite widget-preloader-3;
  left: 4em;
}

@keyframes widget-preloader-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes widget-preloader-2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(2em);
  }
}

@keyframes widget-preloader-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=index.dba9e274.css.map */
