// Preloader
.widget-preloader-frame
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  position: absolute
  left: 0
  top: 0
  z-index: 1000
  background-color: #fff
  transition: opacity 2s

  &.hidden, &.hidden .widget-preloader
    pointer-events: none
    opacity: 0

  .widget-preloader
    font-size: 24px
    @media (max-width: 1024px)
      font-size: 18px
    @media (max-width: 320px)
      font-size: 12px
    width: 5em
    height: 5em
    position: relative

    div
      width: 1em
      height: 1em
      position: absolute
      top: 2em
      border-radius: 50%
      background-color: #FFD180
      animation-timing-function: cubic-bezier(0, 1, 1, 0)
      &:nth-child(1)
        left: 0em
        animation: widget-preloader-1 0.6s infinite
      &:nth-child(2)
        left: 0em
        animation: widget-preloader-2 0.6s infinite
      &:nth-child(3)
        left: 2em
        animation: widget-preloader-2 0.6s infinite
      &:nth-child(4)
        left: 4em
        animation: widget-preloader-3 0.6s infinite

@keyframes widget-preloader-1
  0%
    transform: scale(0)
  100%
    transform: scale(1)

@keyframes widget-preloader-2
  0%
    transform: translateX(0)
  100%
    transform: translateX(2em)

@keyframes widget-preloader-3
  0%
    transform: scale(1)
  100%
    transform: scale(0)
